import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../shared/store/application-state';
import { AddAjaxRotatorAction, RemoveAjaxRotatorAction } from './store/navigation.actions';

@Injectable()
export class AjaxRotatorService {
	private counter = 0;
	private activeRotators = new Set();

	constructor(private store: Store<ApplicationState>) {
	}

	public addRotator(): number {
		const id = this.counter++;
		this.activeRotators.add(id);
		this.store.dispatch(new AddAjaxRotatorAction());
		return id;
	}

	public addPageLoadRotator(): void {
		this.activeRotators.add('pageLoad');
		this.store.dispatch(new AddAjaxRotatorAction());
	}

	public removePageLoadRotator(): void {
		this.removeRotator('pageLoad' as unknown as number); // sorry
	}

	public removeRotator(id: number): void {
		this.activeRotators.delete(id);
		if (!this.activeRotators.size) {
			this.store.dispatch(new RemoveAjaxRotatorAction());
		}
	}
}