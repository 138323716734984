import { Injectable } from '@angular/core';
import { WebserviceExceptionComponent } from './webservice-exception.component';

export interface ProblemJson {
	type: string;
	status: number;
	details: string[];
	message?: string;
	stacktrace?: string[];
}

@Injectable({ providedIn: 'root' })
export class WebserviceExceptionService {
	private outputComponent?: WebserviceExceptionComponent;

	constructor() {
	}

	public handleError(error: ProblemJson): void {
		this.outputComponent?.displayError(error);
	}

	public clearErrors(): void {
		this.outputComponent?.clearErrors();
	}

	public registerOutput(outputComponent: WebserviceExceptionComponent): void {
		this.outputComponent = outputComponent;
	}

	public removeOutput(): void {
		this.outputComponent = null;
	}
}